@import url("https://fonts.googleapis.com/css2?family=Gabarito&family=Montserrat&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  /* 1 */
  line-height: inherit;
  /* 2 */
}

body::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
